<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <vb-headers-tag :data="{ title: 'Slider page d\'acceuil' }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header py-0">
            <vb-headers-card-header-4 :data="headerData" />
          </div>
          <div class="card-body">
            <div class="d-flex flex-wrap mb-4">
              <vb-controls-button-1
                :data="{ title: 'Ajouter slider', icon: 'fe fe-plus-circle', type: 'primary' }"
                @click="addSlider"
              />
            </div>
            <div class="d-flex flex-wrap mb-2">
              <div class="d-flex flex-wrap">
                <div :class="$style.event">
                  <div class="vb__utils__donut vb__utils__donut--success" />
                  Activé
                </div>
                <div :class="$style.event">
                  <div class="vb__utils__donut vb__utils__donut--danger" />
                  Désactivé
                </div>
              </div>
            </div>
            <div :class="$style.items">
              <div v-for="(item, index) in dataSource" :key="index" :class="$style.item">
                <div v-if="item.status === 'active'" :class="$style.itemContent">
                  <div :class="$style.itemControl">
                    <div :class="$style.itemControlContainer">
                      <a-button-group>
                        <a-button @click="handlePreview(item)"><SearchOutlined /></a-button>

                        <a-tooltip>
                          <template #title>Modifier l'ordre de l'image</template>
                          <a-dropdown>
                            <template #overlay>
                              <a-menu @click="(e)=>changeNumber(e,item)">
                                <a-menu-item
                                  v-for="num in Array.from({length: count}, (_, i) => i + 1)"
                                 :key="num"
                                >
                                  placez-le numéro {{num}}
                                </a-menu-item>
                              </a-menu>
                            </template>
                            <a-button>
                              Numéro {{item.order?item.order:""}}
                              <DownOutlined />
                            </a-button>
                          </a-dropdown>                          
                        </a-tooltip>
                        <a-tooltip>
                          <template #title>Desactiver l'image</template>
                          <a-button @click="changeImageStatus(item, 'inactive')"
                            ><DeleteOutlined
                          /></a-button>
                        </a-tooltip>
                      </a-button-group>
                    </div>
                  </div>
                  <img :src="baseUrl + item.url" alt="Image" />
                </div>
                <div v-else :class="$style.itemContentInactive">
                  <div :class="$style.itemControl">
                    <div :class="$style.itemControlContainer">
                      <a-button-group>
                        <a-button @click="handlePreview(item)"><SearchOutlined /></a-button>
                        <a-tooltip >
                          <template #title>Activer l'image</template>
                          <a-button @click="changeImageStatus(item, 'active')"
                            ><ToTopOutlined
                          /></a-button>
                        </a-tooltip>
                      </a-button-group>
                    </div>
                  </div>
                  <img :src="baseUrl + item.url" alt="Image" />
                </div>
                <div class="d-flex justify-content-between">

                    <div>
                      Date de création : {{ new Date(item.createdAt).toLocaleDateString('fr-FR') }}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal :visible="previewVisible" :footer="null" :width="1200" @cancel="handleCancel">
      <img alt="slider" style="width: 100%" :src="baseUrl + previewImage" />
    </a-modal>
    <a-modal
      v-model:visible="visibleModal"
      title="Ajouter slider"
      :width="400"
      style="height: 100px"
      ok-text="Ajouter"
      @ok="onSubmit"
      @cancel="cancel"
    >
      <a-form
        label-align="left"
        class="container"
        :rules="rules"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 12 }"
      >
        <div class="row">
          <div class="col md-6">
            <a-upload-dragger
              :file-list="fileList"
              name="file"
              :multiple="false"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              :custom-request="
                () => {
                  return true
                }
              "
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text">Click or drag file to area to upload</p>
              <p class="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data
                or other band files
              </p>
            </a-upload-dragger>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import VbHeadersTag from '@/components/widgets/Headers/Tag'
import VbHeadersCardHeader4 from '@/components/widgets/Headers/CardHeader4'
import { ref, computed } from 'vue'
import {
  DeleteOutlined,
  SearchOutlined,
  ToTopOutlined,
  DownOutlined
} from '@ant-design/icons-vue'
import VbControlsButton1 from '@/@vb/widgets/Controls/Button'
import { message } from 'ant-design-vue'
import ApiClient from '@/services/axios'

export default {
  components: {
    ToTopOutlined,
    SearchOutlined,
    DeleteOutlined,
    VbHeadersTag,
    VbHeadersCardHeader4,
    VbControlsButton1,
    DownOutlined
  },
  setup() {
    const visibleModal = ref(false)
    const fileList = ref([])
    const baseUrl = ref(process.env.VUE_APP_API_URL)
    const dataSource = ref([])
    ApiClient.post('/slider/filter', {
      query: {},
    })
      .then((res) => {
        res.data
          .sort((a, b) => a.status.localeCompare(b.status))
        dataSource.value = res.data
      })
      .catch((err) => console.log(err))
    const count = computed(() => [...dataSource.value].filter((elem)=>elem.status=="active").length)
    const previewVisible = ref(false)
    const previewImage = ref('')
    const headerData = ref({
      title: 'Gestion des sliders',
      icon: 'fe fe-users',
      description: "Personnaliser votre interface d'acceuil ( 1520 x 300 )",
      res: {},
    })
    const changeNumber = ({key}, item) =>{
      ApiClient.patch('/slider/' + item._id, {
        data: { order: key },
      })
        .then(() => {
          dataSource.value = dataSource.value.map((slider)=>{
            if(slider._id==item._id) return {...slider,order: key}
            else return slider
          })
        })

        .catch(function () {
          message.error("Impossible de supprimer l'image.")
        })      
    }
    const handlePreview = (item) => {
      previewImage.value = item.url
      previewVisible.value = true
    }
    const changeImageStatus = (item, newStatus) => {
      ApiClient.patch('/slider/' + item._id, {
        data: { status: newStatus },
      })
        .then(() => {
          visibleModal.value = false
          message.success(`image ${newStatus=="active"?"activé":"Désactivé"}`)
          dataSource.value = dataSource.value.map((slider)=>{
            if(slider._id==item._id) return {...slider,status: newStatus}
            else return slider
          })
        })

        .catch(function () {
          message.error("Impossible de supprimer l'image.")
        })
    }
    const handleCancel = () => {
      previewVisible.value = false
    }
    const addSlider = (record) => {
      visibleModal.value = true
    }
    const onSubmit = () => {
      if (fileList.value.length > 0) {
        ApiClient.put('/slider', {
          data: {},
        })
          .then((res) => {
            if (fileList.value.length > 0) {
              let formData = new FormData()
              formData.append('slide', fileList.value[0], res.data._id)
              ApiClient.patch('/slider/files/' + res.data._id, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
                .then(() => {
                  visibleModal.value = false
                  message.success(`Slider ajouté`)
                  window.location.reload()
                })

                .catch(function () {
                  message.error("Impossible de télécharger l'image.")
                })
            }

            //this.$emit('memberAdded', res.data)
          })
          .catch((e) => {
            message.warning("Impossible d'ajouter les information saisis")
          })
      }
    }
    const handleChange = (info) => {
      if (info.file.status !== 'uploading') {
        console.log(info.file)
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
    }
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!')
      }

      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
      }
      if (isJpgOrPng && isLt2M) {
        message.success(`${file.name} fichier téléchargé avec succès..`)
        fileList.value = [...fileList.value, file]
      }
      return isJpgOrPng && isLt2M
    }
    return {
      dataSource,
      headerData,
      previewImage,
      previewVisible,
      handleCancel,
      handlePreview,
      visibleModal,
      fileList,
      handleChange,
      beforeUpload,
      handleRemove,
      onSubmit,
      addSlider,
      baseUrl,
      changeImageStatus,
      count,
      changeNumber
    }
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="less" scoped>
.ant-modal-body {
  height: 220px;
}
</style>
